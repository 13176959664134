* {
  /* background-color: #141414; */
  font-family: "Poppins", sans-serif;
  background-color: "#000000";
  color: "white";
}

.iconeAnimado {
  overflow: hidden;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.iconeAnimado:hover {
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.cardd:hover {
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(1.1);
}

.textoinicio:hover {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1.05);
  cursor: pointer;
}

.textoinicio p {
  color: "white";
}

#basic-nav-dropdown {
  color: white;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.icone:hover {
  -moz-transform: scale(2);
  -webkit-transform: scale(1);
  transform: scale(1.2);
}

#basic-nav-dropdown {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
