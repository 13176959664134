.cardd {
  height: 300px;
  width: 320px;
  overflow: hidden;
  text-align: center;
  min-height: 450px;
  margin-bottom: 20px;

  flex-wrap: wrap;
  display: flex;
}

.cardd img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (orientation: portrait) {
  .cardd {
    width: 100%;
    min-height: 10px;
    text-align: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
}

@media only screen and (orientation: landscape) {
  .cardd {
    width: 100%;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
